import {
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
} from '@momentum-ui/react'
import React from 'react'
import { ISchemaObject } from '../../interfaces/schemaObjectInterfaces'
import NestedProperties from './NestedProperties'

interface ISchemaObjectTable {
  name: string
  schemaObject: ISchemaObject
  markRequiredItems: boolean
}

const SchemaObjectTable: React.FC<ISchemaObjectTable> = ({
  name,
  schemaObject,
  markRequiredItems,
}) => {
  return (
    <section
      className={`schema-object-table ${
        !markRequiredItems && 'dont-display-required'
      }`}
    >
      <Accordion
        multipleVisible
        className="schema-object-table container"
        initialActive={[0]}
        initialActiveFocus={false}
      >
        <AccordionGroup>
          <AccordionHeader className="accordion-header">
            <h2>{name}</h2>
          </AccordionHeader>
          <AccordionContent>
            <ul className="body property-list-item">
              <NestedProperties
                schemaObject={schemaObject.items || schemaObject}
                level={0}
                shouldIncrement={true}
              />
            </ul>
          </AccordionContent>
        </AccordionGroup>
      </Accordion>
    </section>
  )
}

export default SchemaObjectTable
