import React from 'react'
import { ISchemaObject } from '../../../interfaces/schemaObjectInterfaces'
import { makeArrayTypeString } from '../../../utils/general.utils'
import PropertyListItem from '../../PropertyListItem'

interface INestedPropertiesProps {
  schemaObject: ISchemaObject
  level: number
  shouldIncrement: boolean
}

const NestedProperties: React.FC<INestedPropertiesProps> = ({
  schemaObject,
  level,
  shouldIncrement,
}): JSX.Element => {
  const determineLevel = (): number => {
    if (shouldIncrement) {
      return level ? level + 1 : 1
    } else {
      return level ? level - 1 : 1
    }
  }

  const determineDirection = (): boolean => {
    const maxLevel = 3
    const minLevel = 1
    if (level === minLevel) {
      return true
    } else if (level === maxLevel) {
      return false
    } else {
      return shouldIncrement
    }
  }
  return (
    <>
      {schemaObject.properties?.map((property, i) => {
        const required =
          schemaObject.required &&
          schemaObject.required.includes(property.name as string)
        return (
          <PropertyListItem
            name={property.name as string}
            description={property.description}
            example={property?.items?.example || property.example}
            type={makeArrayTypeString(property.items?.type) || property.type}
            key={i}
            className={`child-${level}`}
            required={required}
            format={
              makeArrayTypeString(property.items?.format) || property.format
            }
          >
            {property.properties ? (
              <NestedProperties
                schemaObject={property}
                level={determineLevel()}
                shouldIncrement={determineDirection()}
              />
            ) : null}
            {property.items && property.items?.properties ? (
              <NestedProperties
                schemaObject={property.items}
                level={determineLevel()}
                shouldIncrement={determineDirection()}
              />
            ) : null}
          </PropertyListItem>
        )
      })}
    </>
  )
}

export default NestedProperties
