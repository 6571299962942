import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ApiRoute from '../../components/ApiRoute'
import CodeSnippet from '../../components/CodeSnippet'
import Link from '../../components/Link'
import SchemaObjectTable from '../../components/SchemaObjectTable'
import { useApiVersions } from '../../hooks/useApiVersions'
import { IWebhook } from '../../interfaces/webhookInterfaces'
import ApiLayout from '../../layouts/ApiLayout'

export interface IWebhookDocumentationProps {
  pageContext: {
    name: string
    resourceName: string
    version: number
  }
  data: {
    strapiWebhooks: IWebhook
  }
}

export const query = graphql`
  query WebhookQuery($name: String!, $version: Int!) {
    strapiWebhooks(name: { eq: $name }, version: { eq: $version }) {
      data
    }
  }
`

const WebhookDocumentation: React.FC<IWebhookDocumentationProps> = ({
  data,
  pageContext,
}): JSX.Element => {
  const { data: webhookDetails } = data.strapiWebhooks
  const {
    resourceName,
    name: webhookName,
    version: apiCurrentVersion,
  } = pageContext
  const { t } = useTranslation()
  const apiVersionNums = useApiVersions(webhookName, 'webhooks')

  const leftSideContents = (
    <>
      {webhookDetails.eventPayload && (
        <SchemaObjectTable
          name={t('webhookDocumentation.eventPayload')}
          schemaObject={webhookDetails.eventPayload}
          markRequiredItems={false}
        />
      )}
    </>
  )

  const rightSideContents = (
    <div className="webhook-example">
      <CodeSnippet
        canCopy
        codeString={JSON.stringify(webhookDetails.example, null, 2)}
        language="json"
        headerContentsLeftSide={
          <ApiRoute badgeType="event" endpoint={webhookDetails.eventType} />
        }
        displayLineNumbers
      />
      <p className="subscribe-sentence">
        <span>{t('webhookDocumentation.subscribe1')}</span>
        <Link to="/documentation/subscriptions">
          {t('webhookDocumentation.subscribe2')}
        </Link>
        <span>{t('webhookDocumentation.subscribe3')}</span>
      </p>
    </div>
  )

  return (
    <ApiLayout
      apiName={webhookName}
      resourceName={resourceName}
      description={webhookDetails.description}
      endpoint={webhookDetails.eventType}
      badgeType="event"
      leftSideContents={leftSideContents}
      rightSideContents={rightSideContents}
      versions={apiVersionNums}
      currentVersion={apiCurrentVersion}
    />
  )
}

export default WebhookDocumentation
